.common_tree_handle[data-v-230bf2b6] {
  width: 240px;
}
.el-main[data-v-230bf2b6] {
  border-radius: 4px;
  background-color: #FFFFFF;
  position: relative;
}
.disflex[data-v-230bf2b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cardBigBox[data-v-230bf2b6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cardBigBox .cardbox[data-v-230bf2b6] {
  width: 33.3%;
  margin-bottom: 30px;
}
.cardBigBox .cardbox .card[data-v-230bf2b6] {
  width: 90%;
  margin: auto;
  cursor: pointer;
}
.cardBigBox .cardbox .card .cardTop[data-v-230bf2b6] {
  width: calc(100% - 26px);
  padding: 12px;
  border: 1px solid #DCDFE6;
}
.cardBigBox .cardbox .card .cardTop .orderImg[data-v-230bf2b6] {
  width: 35px;
  height: 35px;
}
.cardBigBox .cardbox .card .cardTop .topContent[data-v-230bf2b6] {
  margin-left: 8px;
  color: #909399;
  font-size: 14px;
  line-height: 25px;
}
.cardBigBox .cardbox .card .cardTop .topContent p[data-v-230bf2b6] {
  margin: 0;
}
.cardBigBox .cardbox .card .cardTop .topContent span[data-v-230bf2b6] {
  color: #333333;
}
.cardBigBox .cardbox .card .cardBottom[data-v-230bf2b6] {
  width: 100%;
  background-color: #DCDFE6;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.block[data-v-230bf2b6] {
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  padding: 12px 24px;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}